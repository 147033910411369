import { BehaviorSubject, Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { EventEmitter, Injectable, Output } from '@angular/core';

import { ApiService } from "src/app/shared/services/api";
import { AppTargetEntity } from "../models/appTarget";
import { BusinessUnitEntity } from "../models/unit";
import { HttpClient } from '@angular/common/http';
import { LocalstorageService } from "src/app/local.storage.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ResponseRecords } from "../models/responseRecords";
import { ShowHelpCommand } from "../models/help-model";
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApplicationService {

  public lastHelpCommand: ShowHelpCommand;
  public onHelpNotifier = new EventEmitter<ShowHelpCommand>();
  public onCloseHelpNotifier = new EventEmitter<ShowHelpCommand>();

  public photoBasePath = `${environment.urls.filesUrl}`;
  public tripAdvisorBasePath = `${environment.urls.bffUrl}/unit/getTripadvisorHtml`;
  public unitService: ApiService<BusinessUnitEntity, ResponseRecords<BusinessUnitEntity[]>>;
  public appService: ApiService<AppTargetEntity, ResponseRecords<AppTargetEntity[]>>;


  private smallMobileEvent = new BehaviorSubject<boolean>(false);
  get onSmallMobile$(): Observable<boolean> {
    return this.smallMobileEvent.asObservable();
  }


  private mediumScreenEvent = new BehaviorSubject<boolean>(false);
  get onMediumScreen$(): Observable<boolean> {
    return this.mediumScreenEvent.asObservable();
  }


  public loadingEvent = new BehaviorSubject<boolean>(false);
  get onLoading$(): Observable<boolean> {
    return this.loadingEvent.asObservable();
  }


  constructor(private http: HttpClient, private responsive: BreakpointObserver, private ngxLoader: NgxUiLoaderService) {
    this.unitService = new ApiService(http, `${environment.urls.bffUrl}/unit`);
    this.appService = new ApiService(http, `${environment.urls.bffUrl}/appTarget`);

    this.responsive.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.smallMobileEvent.next(result.matches);
    });

    this.responsive.observe([
      Breakpoints.Medium,
    ]).subscribe(result => {
      this.mediumScreenEvent.next(result.matches);
    });

  }


  getApp(currentUrl: string, localStorageService: LocalstorageService): Promise<AppTargetEntity> {
    return new Promise(async (resolve) => {

      let appDataStorage = await localStorageService.getItem(`stApp_${environment.appTargetId}`);

      try { this.ngxLoader.start(); } catch (error) { }

      if(appDataStorage) {
        let data: AppTargetEntity = JSON.parse(appDataStorage) as AppTargetEntity;
        await this.appService.setItem(data);
        this.stopLoader();
        resolve(data);
      } else {
        let app = await this.appService.getById(`${environment.appTargetId}`).then();
        localStorageService.setItem(`stApp_${environment.appTargetId}`, JSON.stringify(app));
        this. stopLoader();
        resolve(app);
      }


    });
  }

  stopLoader() {
    setTimeout(() => {
      try { this.ngxLoader.stop(); } catch (error) { }
    }, 200);
  }


  getUnits(currentUrl: string): Promise<ResponseRecords<BusinessUnitEntity[]>> {
    return new Promise(async (resolve) => {

      let units = await this.unitService.get(`${environment.appTargetId}/getUnits`).then();
      resolve(units);

      // let stUnits = this.localStorageService.getItem('stUnits');
      // if (stUnits) {
      //   let resp = JSON.parse(stUnits) as ResponseRecords<BusinessUnitEntity[]>;
      //   this.unitService.setList(resp);
      //   resolve(resp);
      // } else {
      //   let units = await this.unitService.get(`getUnits`).then();
      //   this.localStorageService.setItem('stUnits', JSON.stringify(units));
      //   resolve(units);
      // }

    });
  }


}
